@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700;800;900&display=swap');

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

body {
  background-color: #151617;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
h1, h2 {
  font-weight: bold;
}
h1 {
  font-family: 'Inter', sans-serif;
  font-size: 1.0em;
}
h2 {
  font-family: 'Inter', sans-serif;
  font-size: 0.8em;
}
p {
  margin: 0 auto;
  line-height: 100%;
  max-width: 900px;
}
p.left, h2.left {
  text-align: left;
}
span.large {
  font-size: 28px;
}
span.small, p.small {
  font-size: 22px;
  line-height: 150%;
  margin: 15px auto;
}
span.smaller {
  font-size: 16px;
}
span.tiny {
  font-size: 14px;
  line-height: 100%;
}
ul {
  margin: 0 auto;
  text-align: left;
}
li {
  line-height: 150%;
}
a {
  color: aquamarine;
  text-decoration: none;
}
a:visited {
  outline: none;
}
table {
  margin: 0 auto;
  max-width: 96%;
}
td {
  text-align: left;
  vertical-align: top;
  padding-right: 15px;
  padding-bottom: 30px;
  font-size: 24px;
  line-height: 150%;
}
footer {
  font-size: 14px;
  padding: 30px;
  margin-top: 60px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  margin-top: 3px;
}
input[type=radio] {
  margin-right: 30px;
}
button.primary {
  width: 300px;
  height: 46px;
  margin: 30px auto;
  background-color: #1E90FF;
  color: #FFFFFF;
  font-size: 22px;
  border: none;
  border-radius: 15px;
  outline: 0;
  -moz-outline-style: none;
  cursor: pointer;
}
button.secondary {
  width: 300px;
  height: 46px;
  margin: 30px auto;
  background-color: #FFFFFF;
  color: #1E90FF;
  font-size: 22px;
  border: none;
  border-radius: 15px;
  outline: 0;
  -moz-outline-style: none;
  cursor: pointer;
}
button.tertiary {
  width: 300px;
  height: 46px;
  margin: 30px auto;
  background-color: aquamarine;
  color: #black;
  font-size: 22px;
  border: none;
  border-radius: 15px;
  outline: 0;
  -moz-outline-style: none;
  cursor: pointer;
}
.button-separator {
  width: 30px;
  height: 50px;
  margin: 0 auto;
  display: inline-block;
}
@media (max-width: 991px) {
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.2em;
  }
  p {
    font-size: calc(7px + 2vmin);
    line-height: 200%;
    max-width: 85%;
  }
  span.large {
    font-size: 20px;
  }
  p.small, span.small {
    font-size: 16px;
  }
  ul {
    width: 80%;
    padding-left: 20px;
  }
  td {
    font-size: 14px;
  }
  footer {
    font-size: 1.5vh;
  }
  button.primary, button.secondary {
    width: 260px;
    height: 40px;
    font-size: 16px;
    margin: 20px auto;
  }
  .button-separator {
    display: none;
  }
}
