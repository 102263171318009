.eco {
  color: aquamarine;
}
.App {
  text-align: center;
  min-height: 100vh;
  width: 100vw;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-content {
  min-height: 90vh;
  width: 100vw;
}
.top-logo {
  height: 8vmin;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -15px;
  cursor: pointer;
}
.text-content {
  margin: 0 auto;
  max-width: 900px;
}
.underlined {
  width: 100%;
  border-bottom: 1px solid #EEEEEE;
}
#menu-section {
  margin: 0 auto;
  width: 100%;
  height: 2vh;
  max-width: 1200px;
  position: relative;
}
.support {
  margin-top: -10px !important;
  padding: 10px;
  color: black !important;
  background-color: aquamarine;
}
.problem-solution {
  color: #222222;
  padding: 30px;
  width: 100%;
  background-color: #FFFFFF;
}
ul.problem-solution {
  width: 550px;
  margin: 0 auto;
}
.bg-nature {
  background-size: cover;
}
h2.bg-nature {
  color: green;
}
ul.bg-nature {
  width: 550px;
  height: auto;
  color: green;
  font-weight: bold;
  background: transparent url(assets/images/bg-white.png) top left repeat;
}
div.phone-images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px auto;
  width: 75%;
  height: auto;
}
img.phone-images {
  width: auto;
  height: 50vh;
  margin: 0 30px;
}
img.investor-slide {
  height: 75vh;
  width: auto;
  margin: 30px auto;
}
.social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
}
img.social {
  margin: 15px;
  width: 42px;
  height: 42px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: fadeIn ease 10s;
    -webkit-animation: fadeIn ease 10s;
    -moz-animation: fadeIn ease 10s;
    -o-animation: fadeIn ease 10s;
    -ms-animation: fadeIn ease 10s;
  }
  #home-unit {
    animation: fadeIn ease 20s;
    -webkit-animation: fadeIn ease 20s;
    -moz-animation: fadeIn ease 20s;
    -o-animation: fadeIn ease 20s;
    -ms-animation: fadeIn ease 20s;
  }
}

.App-section {
  background-color: #151617;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  background-color: #151617;
  min-height: 5vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 99%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 100000;
}

.App-link {
  color: #61dafb;
}
.indicates-required {
  font-size: 16px;
}
.mc-field-group {
  width: 33%;
  margin: 0 auto;
  text-align: left;
  font-size: calc(10px + 1vmin);
}
textarea.mc-field-group {
  width: 100%;
}
div.mc-field-group {
  margin: 30px auto;
}
input.mc-field-group {
  width: 300px;
  float: right;
  display: inline-block;
  color: #000000;
  padding: 5px;
  text-align: left;
  font-size: calc(8px + 1vmin);
}
ul.mc-field-group {
  list-style-type: none;
}
li.mc-field-group {
  list-style-type: none;
}
.section-top-padding {
  width: 100%;
  height: 4vh;
}
.desktop-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
a.desktop-menu {
  color: #FFFFFF;
  margin: 0 15px;
  font-size: calc(4px + 1vmin);
  text-decoration: none;
}
.mobile-menu {
  display: none;
}
#animation {
  position: absolute;
  top: 10vh;
  left: 2.5%;
  z-index: 0;
  overflow:hidden;
}
#homepage, #aboutus {
  position: absolute;
  width: 100%;
  top: 14vh;
  left: 0;
  z-index: 200;
}
#home-unit {
  width: 100%;
  height: auto;
}

.unit-images {
  height: auto;
  width: 100vw;
  opacity: 0;
  padding: 0;
  margin: 0 auto;
  animation: fadeMe 0.3s linear;
  animation-delay: 2s;
  animation-duration: 3s;
  -webkit-transition: all 3s ease-in-out;
  -moz-transition: all 3s ease-in-out;
  transition: all 3s ease-in-out;
  animation-fill-mode: forwards;
  background-image: linear-gradient(to right, rgba(179, 49, 95, 0.5), rgba(58, 0, 117, 0.5));
}
.img-before-after {
  height: 50vh;
  width: auto;
}
.bins-before {
  background: #000000 url(assets/images/bg-bins-before.jpg) top left repeat;
  background-size: 10vw;
  width: 50vw;
  height: 30vw;
  margin: 0 auto;
}
.relative {
  position: relative;
  z-index: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 60vh;
  max-width: 100vw;
}
.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  max-width: 100vw;
}
#video-home {
  min-width: 100%;
  min-height: 100%;
  max-width: 100vw;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
h1.b0-time {
  position: absolute;
  margin: auto;
  top: 33%;
  z-index: 1;
  text-shadow: 2px 2px 10px #000000;
}
div.media-mentions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 900px;
  max-width: 900px;
  margin: 0 auto 30px;
  background-color: #444444;
}
div.in-the-news {
  margin-right: 30px;
  white-space: nowrap;
  text-align: left;
}
img.media-awards {
  width: 1200px;
  max-width: 67vw;
  height: auto;
  margin: 15px auto;
}
div.media-kit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
  margin: 30px auto;
}
img.podcasts {
  width: 600px;
  height: 600px;
  display: block;
  margin: 30px auto 15px;
}
img.podcasts-icon {
  width: auto;
  height: 5vh;
  margin: 10px;
}
img.media-kit-thumb {
  height: 200px;
  width: 200px;
  margin: 30px 30px 0 30px;
  border: 1px solid #CCCCCC;
}
img.media-kit-thumb-wide {
  height: 200px;
  width: auto;
  border: 1px solid #CCCCCC;
}
div.full-width {
  width: 100%;
  height: auto;
  display: block;
  margin: 30px auto;
}
div.hightlighted-text {
  max-width: fit-content;
  margin: 0 auto;
  padding: 15px;
  background-color: #EEEEEE;
  color: #777;
}

@media (max-width: 991px) {
  .relative, .video-container {
    height: 40vh;
  }
  .App-section {
    min-height: 800px;
    width: 100%;
  }
  .App-header {
    width: 99%;
  }
  .top-logo {
    height: 20vmin;
  }
  .mobile-menu {
    display: inline;
  }
  .desktop-menu {
    display: none;
  }
  .section-top-padding {
    height: 6vh;
  }
  .text-content {
    max-width: 85%;
  }
  #fund-banner {
    position: fixed;
    top: 10px;
    left: 30vw;
    width: 40vw;
    height: 28px;
    color: black;
    padding: 10px;
    border-radius: 5px;
    background-color: aquamarine;
  }
  #homepage {
    top: 16vh;
  }
  .App-logo {
    height: 60vmin;
  }
  #animation {
    left: 0;
    width: 100%;
  }
  div.problem-solution {
    width: 100%;
    padding: 0;
  }
  ul.problem-solution {
    width: 85%;
    font-size: 16px;
    padding: 12px;
  }
  ul.bg-nature {
    width: 85%;
    padding: 12px;
    margin-bottom: 60px;
    color: #FFFFFF;
    text-shadow: 1px 1px 3px #000000;
    background-image: none;
  }
  li.bg-nature {
    margin: 10px 0;
  }
  img.phone-images {
    width: 130px;
    height: 264px;
  }
  img.media-kit-thumb-wide {
    width: 90vw;
    height: auto;
  }
  .unit-images {
    width: 100vw;
  }
  img.investor-slide {
    width: 90%;
    height: auto;
  }
  .mc-field-group {
    width: 90%;
    max-width: 900px;
    font-size: calc(10px + 2vmin);
  }
  input.mc-field-group {
    width: 90%;
    float: left;
    font-size: calc(10px + 2vmin);
    padding: 5px;
    margin: 15px 0 30px;
  }
  img.podcasts {
    width: 75vw;
    height: 75vw;
  }
  img.social {
    margin: 10px;
    width: 32px;
    height: 32px;
  }
  .img-before-after {
    height: auto;
    width: 80%;
  }
  .bins-before {
    background-size: 20vw;
    width: 100vw;
    height: 60vw;
    margin: 0 auto;
  }
  div.media-kit {
    flex-direction: column;
  }
  img.media-awards {
    width: 75vw;
    height: auto;
    margin: 15px auto;
  }
  img.media-kit-thumb {
    height: 180px;
    width: 180px;
    margin: 15px 15px 0 15px;
  }
  div.media-mentions {
    width: 75%;
    padding: 30px;
    text-align: left;
  }
  div.in-the-news {
    width: 40%;
  }
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes fadeMe {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* Arrow & Hover Animation */
#more-arrows {
  width: 75px;
  height: 65px;
}
#more-arrows {
  fill: #FFF;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
}
#more-arrows.arrow-bottom {
  -webkit-transform: translateY(-18px);
  transform: translateY(-18px);
}
#more-arrows.arrow-top {
  -webkit-transform: translateY(18px);
  transform: translateY(18px);
}

polygon {
  fill: #FFF;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
}
polygon.arrow-middle {
  opacity: 0.75;
}
polygon.arrow-top {
  opacity: 0.5;
}
